<template>

  <div class="row">
    <div class="col-12 card">
      <h4>Соответствие матбазы (ОТиТБ)</h4>
      <router-link to="/analytics"><h5><span class="icon-ArrowLeft"></span> Назад к Отчётам по Охране труда</h5></router-link>
      <div class="row justify-content-space-between">
        
        <div class="col-12">
          <strong class="col-5 card-title">Выбрать дату</strong>
          <date-picker class="btn-tab btn-tab-active right-space-16" v-model:value="datefilter" range></date-picker>
          <strong class="col-5 card-title">Год:</strong>
          <button class="btn-tab right-space-16" @click="setYear(['Sun Jan 01 2022 00:00:00', 'Sun Dec 31 2022 00:00:00'])">2022</button>
          <button class="btn-tab" @click="setYear(['Sun Jan 01 2023 00:00:00', 'Sun Dec 31 2023 00:00:00'])">2023</button>
        </div>
      </div>
      <div class="row justify-content-space-between top-24">
          <div class="col-6">
            <div class="row justify-content-space-between">
              <!-- <div class="text-field col-8">
                <div class="text-field__icon">
                  <input class="text-field__search" type="search" name="search" id="search" placeholder="Поиск по таблице" value="">
                  <span class="text-field__aicon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
                  </span>
                </div>
              </div> -->
              <!-- <div class="col-4">
                <router-link to="/reports/1/create"><button class="btn-primary"><span class="icon-Plus" style="left:4px;"></span></button></router-link>
              </div> -->
            </div>
          </div>
      </div>
      <strong class="col-5 card-title" v-if="this.datefilter[0]">Период c {{ format_date(this.datefilter[0]) }} по {{ format_date(this.datefilter[1]) }}</strong>
      <div class="row">

          <table class="col-12">
            <thead>
              <tr>
                <th class="width-25">Организация</th>
                <th class="width-25">Кол-во замечаний</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="company in Companies">
                <td>{{ company.name }}</td>
                <td>{{ countChecklists(company).length }}</td>
              </tr>
            </tbody>
          </table>
      </div>
      <div class="col-12">
        <apexchart type="bar" :options="options" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import moment from 'moment'

export default {
  data() {
    return {
      title: "Справочники",
      datefilter: [],
      year: '',
      search: null,
      countArray: [],
      options: {
        chart: {
          id: 'vuechart-example'
        },
        stroke: {
          curve: 'smooth',
          colors: ['#F26650'],
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: '#F26650',
          type: 'solid',
          opacity: 0.5,
        },
      },
      series: [{
        name: 'Аварии',
      }]
    
    }
  },
  components: { DatePicker },
  computed: {
    Companies () {
      return this.$store.state.companies
    },
    GetCompaniesNames() {
      return this.Companies.map(company => company.name) 
    },
    Checklists () {
      return this.$store.state.checklists.filter((checklist) => checklist.finish == true)
    },

    resultQuery() {
      this.countArray = []
      const byDate = (item) => {
        const itemDate = new Date(item.period);
        return (
          itemDate >= new Date(this.datefilter[0]) &&
          itemDate <= new Date(this.datefilter[1])
        );
      };

      const byTitle = (item) =>
        item.title.toLowerCase().includes(this.search.toLowerCase());

      let results = this.Checklists;

      if (this.search) {
        results = results.filter(byTitle);
      }

      const hasDateFilter =
        this.datefilter?.length >= 2 &&
        this.datefilter[0] &&
        this.datefilter[1];

      if (hasDateFilter) {
        results = results.filter(byDate);
      }
      this.updateCategories()
      this.createCountArray(results)

      return results;
    },

  },
  mounted() {
    this.$store.dispatch("fetchCompanies");
    this.$store.dispatch("fetchChecklists", 5);
  },
  methods: {
    countChecklists(company){
      return this.resultQuery.filter((checklist) => {
          return checklist.company_name.includes(company.name);
        })
    },
    setYear(year){
      new Date(this.datefilter[0] = year[0])
      new Date(this.datefilter[1] = year[1])
      this.year = year
      return
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    createCountArray(results) {
      this.Companies.forEach(company => {
        this.countArray.push(
          results.filter((checklist) => {
            return checklist.company_name.includes(company.name)
          }).length
        )
      })
    },
    updateCategories(){
      this.series = [
        {
          data: this.countArray
        },

      ];
      this.options = {
        fill: {
          colors: '#F26650',
          type: 'solid',
          opacity: 0.5,
        },
        xaxis: {
          categories: this.GetCompaniesNames
        }
      }
    },
  }
};
</script>
